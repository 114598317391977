import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/analytics/ClientOnlyPostHog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatadiniLogo"] */ "/vercel/path0/app/components/Icons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StripePortal","StripeSubscriptionCreationButton"] */ "/vercel/path0/app/components/Submitbuttons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/contact/contact.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CustomProviders"] */ "/vercel/path0/components/CustomProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/GoogleTag.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/components/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitcher"] */ "/vercel/path0/components/theme-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogTrigger","DialogContent"] */ "/vercel/path0/components/ui/dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuGroup","DropdownMenuItem"] */ "/vercel/path0/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.5.0_next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@18._dd3slxnpmigipe5ajp47qv5y4q/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.2.0_next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-do_62qt3r4uqgrxvhvvlspxbtkqjq/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.85.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.85.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.85.0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"poppins\"}");
