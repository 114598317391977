// @ts-nocheck
'use client'

// import React, { useEffect } from 'react';
// // import CustomLayout from './layout';

// const Contact = () => {
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.async = true;
//     script.src = 'https://embed.tawk.to/656a0681bfb79148e5990cc5/1hgj2iqv7';
//     script.charset = 'UTF-8';
//     script.crossOrigin = '*';
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

// };

// export default Contact;

import React, { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    // Creating a script element
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/656a0681bfb79148e5990cc5/1hgj2iqv7';
    script.charset = 'UTF-8';
    script.crossOrigin = '*';
    
    // Appending script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Return an empty div or specific container for the chat widget
  return <div id="tawkToChat"></div>; // Optionally, you can use this div as a placeholder or indicator
};

export default Contact;
