// @ts-nocheck
'use client'


import { PropsWithChildren, useState } from 'react';
/* import { trpc } from '@/app/_trpc/client';
import { absoluteUrl } from '@/lib/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client'; */
import { Provider as ReduxProvider } from 'react-redux'; // Import Redux Provider
import { store } from '@/app/store'; // Import your Redux store


export const CustomProviders = ({ children }: PropsWithChildren) => {
  /* const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: absoluteUrl('/api/trpc'),
        }),
      ],
    })
  ); */

  return (

    <ReduxProvider store={store}> {/* Wrap everything with the Redux Provider */}
      {/* <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}> */}
          {children}
{/*         </QueryClientProvider>
      </trpc.Provider> */}
    </ReduxProvider>


  );
};


