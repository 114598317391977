// @ts-nocheck
"use client"; // Ensures it runs only on the client side

import { useEffect } from "react";
import Script from "next/script";

const GoogleTag = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    // ✅ Use rest parameters instead of `arguments`
    function gtag(...args: any[]) {
      window.dataLayer.push(...args);
    }

    gtag("js", new Date());
    gtag("config", "AW-16918372460");
  }, []);

  return (
    <>
      {/* Google Tag Script */}
      <Script async src="https://www.googletagmanager.com/gtag/js?id=AW-16918372460" />
    </>
  );
};

export default GoogleTag;
